html, body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.background {
  position: fixed;
  z-index: 0;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url('/assets/images/background.png');
  background-repeat: no-repeat;
  background-position: 0px 70px;
  opacity: 30%;
}

#container {
  position: relative;
  z-index: 100;
  
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
  margin: auto;
  max-width: 960px;
  
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

#main-content {
  padding: 15px;
  background-color: rgb(255, 255, 255);
}

#footer {

  margin-top: auto;

  padding: 15px;
  font-size: 85%;
}

.screenshot img {
  display: block;
  max-width: 75% !important;
  margin: auto;
}
