/*
Stripped down version of markdown css from
 https://github.com/sindresorhus/github-markdown-css
*/


.md {
  margin: 0;
  line-height: 1.5;
  word-wrap: break-word;
}


.md a {
  background-color: transparent;
  text-decoration: none;
}

.md a:active,
.md a:hover {
  outline-width: 0;
}

.md b,
.md strong {
  font-weight: 600;
}

.md h1 {
  margin: .67em 0;
  font-weight: 600;
  padding-bottom: .3em;
  font-size: 2em;
}

.md small {
  font-size: 90%;
}

.md sub,
.md sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.md sub {
  bottom: -0.25em;
}

.md sup {
  top: -0.5em;
}

.md img {
  border-style: none;
  max-width: 100%;
  box-sizing: content-box;
}

.md code,
.md kbd,
.md pre,
.md samp {
  font-family: monospace,monospace;
  font-size: 1em;
}

.md figure {
  margin: 1em 40px;
}

.md hr {
  box-sizing: content-box;
  overflow: hidden;
  background: transparent;
  height: .25em;
  padding: 0;
  margin: 24px 0;
  border: 0;
}


.md a:hover {
  text-decoration: underline;
}

.md hr::before {
  display: table;
  content: "";
}

.md hr::after {
  display: table;
  clear: both;
  content: "";
}

.md table {
  border-spacing: 0;
  border-collapse: collapse;
  display: block;
  width: max-content;
  max-width: 100%;
  overflow: auto;
}

.md td,
.md th {
  padding: 0;
}

.md details summary {
  cursor: pointer;
}

.md details:not([open])>*:not(summary) {
  display: none !important;
}

.md kbd {
  display: inline-block;
  padding: 3px 5px;
  font-size: 0.8em;
  line-height: 10px;
  vertical-align: middle;
  border-radius: 6px;
}

.md h1,
.md h2,
.md h3,
.md h4,
.md h5,
.md h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.md h2 {
  font-weight: 600;
  padding-bottom: .3em;
  font-size: 1.5em;
  border-bottom: 1px solid #ccc;
}

.md h3 {
  font-weight: 600;
  font-size: 1.25em;
}

.md h4 {
  font-weight: 600;
  font-size: 1em;
}

.md h5 {
  font-weight: 600;
  font-size: .875em;
}

.md h6 {
  font-weight: 600;
  font-size: .85em;
}

.md p {
  margin-top: 0;
  margin-bottom: 10px;
}

.md blockquote {
  margin: 0;
  padding: 0 1em;
}

.md ul,
.md ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2em;
}

.md ol ol,
.md ul ol {
  list-style-type: lower-roman;
}

.md ul ul ol,
.md ul ol ol,
.md ol ul ol,
.md ol ol ol {
  list-style-type: lower-alpha;
}

.md dd {
  margin-left: 0;
}

.md tt,
.md code {
  font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
  font-size: 0.9em;
}

.md pre {
  margin-top: 0;
  margin-bottom: 0;
  font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
  font-size: 12px;
  word-wrap: normal;
}


.md a:not([href]) {
  color: inherit;
  text-decoration: none;
}


.md p,
.md blockquote,
.md ul,
.md ol,
.md dl,
.md table,
.md pre,
.md details {
  margin-top: 0;
  margin-bottom: 16px;
}

.md blockquote>:first-child {
  margin-top: 0;
}

.md blockquote>:last-child {
  margin-bottom: 0;
}

.md sup>a::before {
  content: "[";
}

.md sup>a::after {
  content: "]";
}


.md h1 tt,
.md h1 code,
.md h2 tt,
.md h2 code,
.md h3 tt,
.md h3 code,
.md h4 tt,
.md h4 code,
.md h5 tt,
.md h5 code,
.md h6 tt,
.md h6 code {
  padding: 0 .2em;
  font-size: inherit;
}

.md ul.no-list,
.md ol.no-list {
  padding: 0;
  list-style-type: none;
}

.md ol[type="1"] {
  list-style-type: decimal;
}

.md ol[type=a] {
  list-style-type: lower-alpha;
}

.md ol[type=i] {
  list-style-type: lower-roman;
}

.md div>ol:not([type]) {
  list-style-type: decimal;
}

.md ul ul,
.md ul ol,
.md ol ol,
.md ol ul {
  margin-top: 0;
  margin-bottom: 0;
}

.md li>p {
  margin-top: 16px;
}

.md li+li {
  margin-top: .25em;
}

.md dl {
  padding: 0;
}

.md dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}

.md dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}

.md table th {
  font-weight: 600;
}

.md table th,
.md table td {
  padding: 6px 13px;
  border: 1px solid black;
}

.md table tr {
  background-color: #fdfdfd;
  border-top: 1px solid black;
}

.md table tr:nth-child(2n) {
  background-color: #eee;
}

.md table img {
  background-color: transparent;
}

.md img[align=right] {
  padding-left: 20px;
}

.md img[align=left] {
  padding-right: 20px;
}


.md code,
.md tt {
  padding: .2em .4em;
  margin: 0;
  font-size: 90%;
  border-radius: 6px;
}

.md code br,
.md tt br {
  display: none;
}

.md del code {
  text-decoration: inherit;
}

.md pre code {
  font-size: 100%;
}

.md pre>code {
  padding: 0;
  margin: 0;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}

.md .highlight {
  margin-bottom: 16px;
}

.md .highlight pre {
  margin-bottom: 0;
  word-break: normal;
}

.md .highlight pre,
.md pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  border-radius: 6px;
}

.md pre code,
.md pre tt {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
}

